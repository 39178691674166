<template>
  <div v-if="!!HomeContent" class="home">
    <v-img
      width="100%"
      height="100%"
      :lazy-src="'/media/' + HomeContent.homeBgImageHover.id"
      :src="'/media/' + HomeContent.homeBgImageHover.id"
      class="bg-image"
      :class="{ visible: isHover || isClicked }"
    />
    <div class="content">
      <div class="title">
        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 28">
          <path d="M48.974 0c.052.725-.025 1.528.026 2.305-.743.051-1.512.026-2.281.026L46.744 0h2.23zM49 3.807l-.026 2.485v21.519H46.72V3.781l.051-.052h2.179l.051.078zM7.885 16.59a.532.532 0 00-.526-.543H5.101v-2.232h5.086V25.98a2.01 2.01 0 01-2 2.02H2c-1.105 0-2-.904-2-2.02V2.061a2.01 2.01 0 012-2.02h8.115v2.367H2.784a.538.538 0 00-.536.541v22.174c0 .298.238.541.536.541h4.57c.295 0 .536-.24.536-.538V16.59h-.005zm14.598 9.367c0 1.113-.849 2.014-1.954 2.014-1.512.026-3.365 0-4.852.026-.846-.051-1.512-.57-1.768-1.346l-.077-.57c-.026-1.165 0-12.144 0-12.144h2.255v11.25c0 .299.241.508.536.508l3.07-.002a.539.539 0 00.537-.541V13.937h2.255v12.02h-.002zm18.676-.21h-1.712c-.295 0-.536-.202-.536-.502v-9.087c0-.298.238-.502.536-.502h1.712v.005c.295 0 .536.241.536.541v1.984h2.255v-2.3c0-1.116-.892-1.973-1.994-1.973h.115-3.378c-1.105 0-1.994.857-1.994 1.973v10.14c0 1.114.892 1.974 1.994 1.974h3.286c.897 0 1.59-.544 1.871-1.372.154-1.062.077-3.408.077-3.408h-2.23v1.983a.538.538 0 01-.536.542m-10.588.002H28.86c-.295 0-.535-.202-.535-.502v-9.087c0-.298.238-.502.535-.502h1.713v.005c.294 0 .533.241.533.541v1.984h2.255v-2.3c0-1.116-.892-1.973-1.994-1.973h.115-3.378c-1.105 0-1.994.857-1.994 1.973v10.14c0 1.114.892 1.974 1.994 1.974h3.286c.897 0 1.59-.544 1.871-1.372.154-1.062.077-3.408.077-3.408h-2.23v1.983a.538.538 0 01-.533.542"/>
        </svg>
        <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.74 21.34">
          <path d="M12.61 35.13v-6.66h-7v6.66h-3V16.48a2.55 2.55 0 01.8-2 3.09 3.09 0 012.13-.72h7.61a2.15 2.15 0 011.85.85 3.34 3.34 0 01.64 2.07v18.45zm0-17.54c0-.51-.31-.77-.93-.77H6.55c-.68 0-1 .19-1 .59v8h7.07zM30 35.13V27.3a1.22 1.22 0 00-1.38-1.39H23v9.22h-3V13.79h9.58a3.87 3.87 0 011.43.25 3.63 3.63 0 011.09.69 3.07 3.07 0 01.68 1 2.64 2.64 0 01.25 1.12v4.61a3.24 3.24 0 01-1.35 3 2.58 2.58 0 011.35 2.49v8.21zM29.93 18q0-1.32-1.11-1.32H23v6.17h5.57c.93 0 1.39-.38 1.39-1.14zM41 35.13q-3.52 0-3.52-3.84V17q0-3.23 3.52-3.23h6.06a3.47 3.47 0 011.23.22 3.9 3.9 0 011.1.6 3.49 3.49 0 01.81.91 2.21 2.21 0 01.31 1.11v3.71h-2.94v-2.13a1.5 1.5 0 00-.36-1.14 1.72 1.72 0 00-1.21-.34h-4.41a1.06 1.06 0 00-1.19 1.22V31c0 .74.5 1.12 1.52 1.12h4.43a1 1 0 001-.41 2.24 2.24 0 00.25-1.2v-2.35h3v4.14c0 1.89-1 2.83-2.93 2.83zM64.71 35.13V26h-7v9.09h-2.89v-21.3h2.93V23h7.07v-9.21h3v21.34zM72.25 35.13V13.79h3v21.34zM86.8 35.13h-3.1l-5.31-21.34h3.29l3.62 15.48 3.94-15.48h3.57zM95.92 35.13V13.79h3v21.34zM106.36 35.13q-3 0-3-2.85V16.61c0-1.88 1-2.82 2.92-2.82h7.18c1.95 0 2.92.9 2.92 2.71v15.85c0 1.85-1 2.78-2.92 2.78zm1.42-18.31c-1 0-1.53.41-1.53 1.24v12.61c0 .95.51 1.43 1.53 1.43H112a1.16 1.16 0 001.32-1.3V18.14a1.17 1.17 0 00-1.32-1.32z" transform="translate(-2.61 -13.79)"/>
        </svg>
      </div>
      <router-link
        @mouseenter.native="isHover = true"
        @mouseleave.native="isHover = false"
        @click.native.prevent="onDiscoverClick()"
        event=""
        :to="{
          name: 'Room',
          params: {
            floor: this.HomeContent.landingFloor || 'ground-floor',
            room: HomeContent.landingRoom._slug
          }
        }"
        v-text="HomeContent.discover"
        class="link text-uppercase"
      />
      <MarkdownBlock
        :text="HomeContent.homeText"
        :inline="true"
        class="location text-uppercase"
        tag="p"
      />
    </div>
  </div>
</template>

<script>
import HomeQuery from '@/graphql/GetHomePage.gql'
import { gsap } from 'gsap'
import PanoCache from '@/utils/pano-cache'
import { MarkdownBlock } from '@monogrid/vue-lib'
import { pushGtmEvent } from '@/utils/analytics-events'

export default {
  name: 'Home',
  apollo: {
    HomeContent: {
      query: HomeQuery,
      variables () {
        return {
          status: 'PUBLISHED',
          id: 'fbbf8c8d-3421-4c8d-8197-796cce822ec5'
        }
      },
      async result () {
        await this.$nextTick()
        this.createTimeline()
        if (this.HomeContent.landingRoom && this.HomeContent.landingRoom.image360) {
          PanoCache.get(this.HomeContent.landingRoom.image360)
        }
      }
    }
  },
  components: { MarkdownBlock },
  data () {
    return {
      isHover: false,
      isClicked: false
    }
  },
  mounted () {
    this.$events.emit('panoles-visibility', false)
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  methods: {
    pushGtmEvent,
    createTimeline () {
      this.tl = gsap.timeline({
        onReverseComplete: this.onReverseComplete
      })
      this.tl.to('.home', { opacity: 1, duration: 0.5 })
      this.tl.fromTo('.home .title svg:first-child', { autoAlpha: 0, translateY: 10 }, { autoAlpha: 1, translateY: 0, duration: 0.5 })
      this.tl.fromTo('.home .title svg:last-child', { autoAlpha: 0, translateY: -10 }, { autoAlpha: 1, translateY: 0, duration: 0.5 }, '-=0.5')
      this.tl.fromTo('.home .location', { autoAlpha: 0, translateY: 10 }, { autoAlpha: 1, translateY: 0, duration: 0.5 }, '-=0.2')
      this.tl.fromTo('.home .link', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5 }, '+=0.4')
      if (this.$root.platform.mobile) {
        this.tl.add(() => { this.isHover = true })
      }
    },
    onDiscoverClick () {
      this.isClicked = true
      this.tl && this.tl.reverse()
      pushGtmEvent(`Clicks on ${this.HomeContent.discover}`, '-', '-')
    },
    onReverseComplete () {
      this.$router.push({
        name: 'Room',
        params: {
          floor: this.HomeContent.landingFloor || 'ground-floor',
          room: this.HomeContent.landingRoom._slug
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active {
  transition-delay: 0s;
}

.fade-leave-active {
  transition-delay: 0.5s;
}

.home {
  background: $bg-gradient;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 2;
  opacity: 0;
  overflow-y: auto;

}

.bg-image {
  position: absolute;
  z-index: 1;
  transition: opacity 0.5s 0.5s;
  opacity: 0;

  &.visible {
    opacity: 1;
    transition-delay: 0s;
  }
}

.content {
  position: relative;
  z-index: 2;
  height: 100%;
  padding: rem(130px 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include breakpoint ('sm-and-down') {
    justify-content: space-around;
    padding: rem(40px 0);
  }

  @include breakpoint ('xs-only') {
    justify-content: space-around;
    padding: rem(60px 0);
  }
}

.title {
  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 275px;
    height: auto;

    @include breakpoint ('sm-only') {
      width: 240px;
    }

    @include breakpoint ('xs-only') {
      width: 220px;
    }

    &:first-child {
      margin-bottom: rem(25px);
    }
  }
}

.link {
  font-size: rem(20px);
  letter-spacing: rem(2px);
  display: inline-block;
  color: $c-white;
  text-decoration: none;
  position: relative;

  @include breakpoint ('xs-only') {
    font-size: rem(14px);
  }

  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 80%;
    background: $c-white;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: left center;
    transition: width 0.2s ease-out;
  }

  &:hover {
    &::after {
      width: 95%;
    }
  }
}

.location {
  font-size: rem(20px);
  letter-spacing: rem(4px);

  @include breakpoint ('xs-only') {
    font-size: rem(14px);
  }
}
</style>
